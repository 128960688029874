import React from "react";
import Hero from "../sections/landing1/Hero";
import Feature from "../sections/landing1/Feature3";
import Content1 from "../sections/landing1/Content1";
import ContentZ from "../sections/landing1/Content-lux";
import PageWrapper from "../components/PageWrapper";
import Feature1 from "../sections/landing1/Feature1";
import Content from "../sections/landing1/Content-black";
const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero />
        <Content1 />
        <Feature1 />
        <Content />
        <ContentZ />
        <Feature />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
