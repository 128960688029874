import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)``;

const ContentWidget = styled(Box)`
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.border}`};
  background-color: ${({ theme }) => theme.colors.light};
  padding: 20px;
  min-width: 100%;
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;

  @media ${device.xl} {
    width: 360px;
    min-width: 360px;
  }
  @media ${device.md} {
    min-height: 100%;
  }
`;

const ContentWidgetIcon = styled(Box)`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.light};
  background-color: ${({ theme }) => theme.colors.secondary};
`;

const ContentCard = ({ children = "" }) => {
  return (
    <ContentWidget>
      <ContentWidgetIcon>
        <i className="fas fa-check"></i>
      </ContentWidgetIcon>
      <div className="d-flex align-items-center">
        <Title variant="card" mb={0}>
          {children}
        </Title>
      </div>
    </ContentWidget>
  );
};

const ContentZ = () => {
  return (
    <>
      {/* <!-- Content section --> */}
      <SectionStyled bg="#f2f2f3">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg="9">
              <div className="text-center">
                <Title>Have a Problem that Needs Solving?</Title>
                <Text>
                  Choose from an array of cloud-based management software
                  solutions:
                </Text>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ContentCard>Preventive Maintenance</ContentCard>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ContentCard>Data Analytics & Reports</ContentCard>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ContentCard>Inventory Solutions</ContentCard>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ContentCard>Task Allocation & Work Order Management</ContentCard>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ContentCard> Equipment Maintenance</ContentCard>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-4"
              data-aos="zoom-in"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ContentCard>Facility Scheduling & Cleaning</ContentCard>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default ContentZ;
